;$(function () {
    "use strict";
    ;var keepAliveInterval = setInterval(function () {
  $.get('/auth/keep-alive', {})
  .then(function () {
  }, function () {
    location = '/';
  });
}, 1800 * 1000);


    if ($('#map').length) {
    var LatLngDefault = [53.8842322, 8.6604714];
    var planes = [];

    var map = L.map('map', {
      //dragging: false,
    }).setView(LatLngDefault, 14);

    var house = L.icon({
      iconUrl: '/images/gmaps-object.png',
      popupAnchor: [0, -36],
      iconAnchor: [18, 36],
    });

    L.tileLayer('https://map.rohdenburg-online.de/?r={s}&z={z}&x={x}&y={y}', {
      maxZoom: 18,
      attribution: '',
      id: 'mapbox.streets',
    }).addTo(map);

    for (var i = 0; i < geo.length; i++) {
      var popup = '<div>\
        <a href="'+geo[i].url+'">' + geo[i].name + '</a><br>\
        '+geo[i].zip+' '+geo[i].city+'\
      </div>';
      var marker = new L.marker([geo[i].latitude, geo[i].longitude], {icon: house})
        //.bindPopup(popup)
        .addTo(map);
    }

    if (geo.length === 1) {
      map.setView([geo[0].latitude, geo[0].longitude], 14);
    }
}

if ($('#map-overview').length) {
    var LatLngDefault = [53.8842322, 8.6604714];
    var planes = [];

    var map = L.map('map-overview', {
      //dragging: false,
    }).setView(LatLngDefault, 14);

    var house = L.icon({
      iconUrl: '/images/gmaps-object.png',
      popupAnchor: [0, -36],
      iconAnchor: [18, 36],
    });

    L.tileLayer('https://map.rohdenburg-online.de/?r={s}&z={z}&x={x}&y={y}', {
      maxZoom: 18,
      attribution: '',
      id: 'mapbox.streets',
    }).addTo(map);

    for (var i = 0; i < geo.length; i++) {
      var popup = '<div>\
        <a href="'+geo[i].url+'">' + geo[i].name + '</a><br>\
        '+geo[i].zip+' '+geo[i].city+'\
      </div>';
      var marker = new L.marker([geo[i].latitude, geo[i].longitude], {icon: house})
        //.bindPopup(popup)
        .addTo(map);
    }

    if (geo.length === 1) {
      map.setView([geo[0].latitude, geo[0].longitude], 14);
    }
}


    autosize($('textarea'));
    $('[href="#agb"]').click(function(e) {
        e.preventDefault();
        $('#agb .button-primary').attr('href', $(this).data('link'));
        $('#agb').modal('show');
    });
    $('#agb .button-primary').click(function () {
        $('#agb').modal('hide');
    })
});
